import Module from './module.js';
import stage from '../components/stage'
import _map from 'lodash.map'
import _each from 'lodash.foreach'
import PerfectScrollbar from 'perfect-scrollbar';

class Submenu extends Module {

  constructor(element, $) {
    super()
    this.body = $('body')
    this.html = $('html')
    this.menu = $('.menu')
    this.element = element
    this.el = $(element)

    this.addEvents()

  }

  addEvents() {

    var scroll = new PerfectScrollbar('.submenu', {});


  }



  destroy(){
    super.destroy()
  }

}



Submenu.selector = `.submenu`;
module.exports = Submenu
