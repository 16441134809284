var Latitude;
var Longitude;

var sortObjectsArray = require('sort-objects-array');
const sortByDistance = require('sort-by-distance')

function sortByName(){
  var location_title = []
  var get_locations = document.getElementsByClassName("location__track")


  Array.prototype.forEach.call(get_locations, function (get_location) {

    var single_location = get_location.getElementsByClassName("location__title")
    var single_order = get_location.getAttribute("order")
    location_title.push({"name" : single_location[0].innerText, "order" : single_order})
  });

  var sort_location = sortObjectsArray(location_title, 'name')
  var i = 1
  sort_location.forEach(function(data){
    $('div[order='+data.order+']').css("order", i);
    i++
  })
}

function sortBydate(){
  $(".location__track").each(function(){
    $(this).removeAttr("style");
  })
}

function sortBylocation(){
  var user_location = getLocation()
  var location_position = []
  var get_locations = document.getElementsByClassName("location__track")

  const origin = { longitude: Longitude, latitude: Latitude}
  const opts = {
    yName: 'latitude',
    xName: 'longitude'
  }

  Array.prototype.forEach.call(get_locations, function (get_location) {

    var single_lat = get_location.getAttribute("lat")
    var single_long = get_location.getAttribute("long")

    // var userLatlong = Longitude + Latitude
    // var latLong = single_long + single_lat
    var single_order = get_location.getAttribute("order")

    location_position.push({"longitude" : single_long, "latitude" : single_lat, "order" : single_order})
  })
    var sort_location = sortByDistance(origin, location_position, opts)
    console.log(sort_location);
    var i = 1
    sort_location.forEach(function(data){
      $('div[order='+data.order+']').css("order", i);
      i++
    })
}


function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition, showError);
  } else {
    return 0
  }
}

function showPosition(position) {
    Latitude = position.coords.latitude
    Longitude = position.coords.longitude
    sortBylocation()
}

function showError(error) {
  switch(error.code) {
    case error.PERMISSION_DENIED:
        console.log( "User denied the request for Geolocation.")
      break;
    case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.")
      break;
    case error.TIMEOUT:
        console.log("The request to get user location timed out.")
      break;
    case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.")
      break;
  }
}


$(document).ready(function(){

  getLocation()

  $('.custom-select').on("click", function(){
    if( $(".select-selected").html() == "După Nume"){
      sortByName()
    }
    if( $(".select-selected").html() == "După Data"){
      sortBydate()
    }
    if( $(".select-selected").html() == "După distanță"){
      if(!Latitude && !Longitude){
        alert("Te rog sa activezi Locatia din browser ca sa iti putem afisa cele mai apropiate locatii!")
      }
      sortBylocation()
    }
  })
})

