import Module from './module.js';
import stage from '../components/stage'
import _map from 'lodash.map'
import _each from 'lodash.foreach'
import PerfectScrollbar from 'perfect-scrollbar';

class Push extends Module {

  constructor(element, $) {
    super()
    this.body = $('body')
    this.html = $('html')
    this.menu = $('.menu')
    this.element = element
    this.el = $(element)
    this.trigger = $('[data-push]')
    this.close = $('[data-behaviour="close-push"]')

    this.addEvents()

  }

  addEvents() {

    var menu_ps = new PerfectScrollbar('.push__window', {});

    this.trigger.on('click', (e)=> {

        e.preventDefault()


        var content = $(e.currentTarget).data('push')
        this.updateContent(content)

        this.disableScroll()
        this.body.addClass('has-push')


    })

    this.close.on('click', (e)=> {

        e.preventDefault()


        this.enableScroll()
        this.body.removeClass('has-push')

    })

  }

  updateContent(content){

    var html = $('#' + content ).html()
    console.log(content);
    $('.push__container').empty().append(html)

  }

  disableScroll(){
    stage.enabled = false
    this.scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    this.body[0].style.position = 'fixed';
    this.body[0].style.top = `-${this.scrollY}`;
  }

  enableScroll(){
    const scrollY = this.body[0].style.top;
    this.body[0].style.position = '';
    this.body[0].style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    stage.enabled = true
    this.body.addClass('close-dropdown').removeClass('has-dropdown')
  }

  destroy(){
    super.destroy()
  }

}



Push.selector = `.push`;
module.exports = Push
