import Module from './module.js';
import Glide from '../components/glide';
import ArrowDisabler from '../components/ArrowDisabler';

class Slider extends Module {

  constructor(element, $) {
    super()
    this.body = $('body')
    this.html = $('html')
    this.element = element
    this.addEvents()

  }

  addEvents() {

    var slides = $( this.element ).find('.section__promotions .glide__slide')
    var bullets = $( this.element ).find('.glide__bullets')
    slides.each((index, el)=>{
        bullets.append(template(index))
    })
    var type = "slider";
    if($(this.element).hasClass("glide_promotions") || $(this.element).hasClass("glide_events")){
      var elementsNumber = 3;
      var tabletPerview = 2;
    }else if($(this.element).hasClass("glide_about")){
      var elementsNumber = 1;
      var tabletPerview = 1;
      var type = "carousel";
    }else{
      var elementsNumber = 4;
      var tabletPerview = 3;
    }

    this.glide = new Glide(this.element, {
      type: type,
      bound: true,
      rewind: false,
      perView: this.element.dataset['perview'] || elementsNumber,
      gap: this.element.dataset['gap'] || 30,
      breakpoints: {
        767: {
          perView: 1 ,
          gap: 20
        },
        1270: {
          perView: tabletPerview ,
          gap: 30
        },
      }
    })

    // this.glide.on(['mount.after', 'run'],  ()=> {
    //     this.glide.index
    // })

    this.glide.mount({ ArrowDisabler });


  }



  destroy(){
    super.destroy()
    // console.log('removeSlider');
    this.glide.destroy()
  }


}


Slider.selector = `.glide`;
module.exports = Slider
