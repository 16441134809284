import animframe from 'raf'

const renderQue = {

    items: [],
    running: false,
    rAF: null,

    render: function () {
        if (!this.items || 0 === this.items.length || !this.running)
        return this.running = false, void animframe.cancel(this.rAF);

        for (var t = 0; t < this.items.length; t++) {
          this.items[t].renderQueCall();
        }

        this.running && (this.rAF = animframe( this.render.bind(this)))
    },

    has: function (t) {
        return -1 != this.items.indexOf(t)
    },

    add: function (t) {

        if (t && this.items) {
            if (!t.renderQueCall) throw new Error("missing caller.");
            -1 === this.items.indexOf(t) && this.items.push(t)
        }
        this.running || (this.running = !0, this.rAF = animframe(this.render.bind(this)))
    },

    remove: function (t) {
        this.items && t && -1 !== this.items.indexOf(t) && this.items.splice(this.items.indexOf(t), 1), 0 === this.items.length && (this.running = !1)
    }

}

module.exports = renderQue
