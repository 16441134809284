import render from '../components/render'
import stage from '../components/stage'
import gsap from 'gsap'

class Module {

  static init(data) {
    var instances = []

    var current = data != undefined ? $(data) : undefined

    $(this.selector, current).each((i, element) => {
      instances.push(new this(element, jQuery));
    });
    return instances
  }

  addScroll() {
    this.resizeQue = this.resize.bind(this)
    stage.on("stage:resize", this.resizeQue);
    this.renderQue = this.render.bind(this)
    stage.on("stage:scroll", this.renderQue);
    this.resize(stage);
  }

  removeScroll() {

    stage.off("stage:resize", this.resizeQue);
    this.resizeQue = null
    stage.ooff("stage:scroll", this.renderQue);
    this.renderQue = null

  }

  destroy(){

  }
}

module.exports = Module;
