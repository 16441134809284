import Module from './module'
import render from '../components/render'
import stage from '../components/stage'
import Dropdown from './dropdown'
import Submenu from './submenu'
import Header from './header'
import gsap from 'gsap'
import _each from 'lodash.foreach'
import bezier from 'bezier-easing'
import geolocation from './geolocation'
import 'jquery.scrollto'
import simpleParallax from 'simple-parallax-js'


let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
class App {
  

  constructor($) {
    this.create()
  }

  create(){
    this.body = $('body')
    this.menu = $('.is-home .menu')
    this.hamburger = $('#menuBtn')
    this.dropdown =  $('.select-selected')

    this.boxes = null
    this.partnersTrack = $('.footer-partners__track')
    this.partnersList = $('.footer-partners__list')
    this.partnersList.clone().appendTo(this.partnersTrack)

    this.x = 0

    
    this.instances = []
    this.elements = [
      //require('./dropdown.js'),
      require('./submenu.js'),
      require('./push.js'),
      require('./header.js'),
      require('./slider.js'),
      //require('./search.js'),
      // require('./geolocation.js')
    ];

    this.addModules()
    this.addEvents()

    this.resizeQue = this.resize.bind(this)
    stage.on("stage:resize", this.resizeQue);
    this.renderQue = this.render.bind(this)
    stage.on("stage:scroll", this.renderQue);
    this.resize(stage);

    render.add(this)
  }
  addEvents() {

    this.hamburger.on('click', (e)=>{

        this.body.toggleClass('has-menu')
        this.body.hasClass('has-menu') && this.disableScroll()
        !this.body.hasClass('has-menu') && this.enableScroll()
        this.body.removeClass('close-dropdown has-dropdown no-transition')
    })

    var image = document.getElementsByClassName('parallax');
    new simpleParallax(image, {
      delay: .1,
      orientation: 'down',
      scale: 1.5,
      overfow: false
    });
  }
  
  resize(e){

  //  console.log(this.boxes);

    if(!e)
			e = stage


    this.partnersWidth = this.partnersList.width()

    _each(this.boxes, (el)=>{
      const box = $(el)
      const rect = box.offset()
      const height = box.height()
      // box.data('bottom', rect.top  + height )
      box.data('top', rect.top)
      box.data('height', height)
    })


    this.render(stage)

  }

  renderQueCall(){
      this.x--
      this.x = this.x < -this.partnersWidth ? 0 : this.x
      TweenMax.set(this.partnersTrack, {x: this.x, force3D: true})
  }

  render(e){

    this.menu.toggleClass('is-fixed', e.scrolltop > 90)
    this.menu.toggleClass('is-visible', e.scrolltop > e.h)


    document.documentElement.style.setProperty('--scroll-y', `${e.scrolltop}px`);

    _each(this.boxes, (el)=>{

      const box = $(el)
      const index = box.data('height')
      const token = box.data('top')
      const inview =  e.scrolltop + e.h > token && e.scrolltop < token + index;
      const loading = e.scrolltop <= token - e.h;

      box.toggleClass('sq--inview', inview)
      box.toggleClass('sq--hide', loading)

    })

  }

  addModules(data){

    this.elements.forEach((m)=> {
      var m = m.init()
      this.instances =  this.instances.concat(m)
    });


  }

  removeModules(){


    stage.removeAllListeners();
    this.boxes = null

    this.instances.forEach((m)=> {
      m.destroy()
    });
    this.instances = []
  }

  disableScroll(){
    stage.enabled = false
    this.scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    this.body[0].style.position = 'fixed';
    this.body[0].style.top = `-${this.scrollY}`;
  }

  enableScroll(){
    const scrollY = this.body[0].style.top;
    this.body[0].style.position = '';
    this.body[0].style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    stage.enabled = true
    this.body.addClass('close-dropdown').removeClass('has-dropdown')
  }



}

module.exports = App
