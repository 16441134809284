import Module from './module.js';
import _map from 'lodash.map'
import _each from 'lodash.foreach'
import gsap from 'gsap'

class Header extends Module {

  constructor(element, $) {
    super()
    this.body = $('body')
    this.html = $('html')
    this.element = element
    this.el = $(element)
    this.nav = $('.slider-nav')
    this.navslides = $('.slider-nav__slide')
    this.slides = $('.slider__slide')
    this.length = this.navslides.length

    this.currentSlide = 0
    this.currentnav = 1

    this.buildNav()
    this.addEvents()

  }

  addEvents() {

    this.navslides.on('click', (e)=> {

        e.preventDefault()
        var el = $(e.currentTarget)
        this.nextSlide();

    })

  }

  nextSlide(slide){

      var temp = this.currentSlide + 1

      var current = circularClamp(0, temp, this.length)
      var currentnav = circularClamp(0, current + 1, this.length)

      $('.is-active', this.el.parent()).removeClass('is-active')

      var currentslide = this.slides.eq(this.currentSlide)
      currentslide.addClass('is-out')
      var nextslide = this.slides.eq(current)
      TweenMax.set(nextslide, {className:"+=is-active", delay:.21})
      TweenMax.set(currentslide, {className:"-=is-out", delay:.21})


      var currentnavslide = this.navslides.eq(this.currentnav)
      currentnavslide.addClass('is-out')


      var nextnavslide = this.navslides.eq(currentnav)
      TweenMax.set(nextnavslide, {className:"+=is-active"})
      TweenMax.set(currentnavslide, {className:"-=is-out", delay:.6})



      $('.progress__item').eq(current).addClass('is-active')

      this.currentSlide = current
      this.currentnav = currentnav

  }




  buildNav(){

    var progress = document.createElement('div')
    progress.className = 'slider-nav__progress'

    _each(this.navslides, (e, index)=>{


      var navitem = document.createElement('div')

      var className = index == 0 ? 'progress__item is-active' : 'progress__item'
      navitem.className = className
      var bar = document.createElement('div')
      bar.className = 'progress__bar'
      navitem.appendChild(bar)
      progress.appendChild(navitem)
    })

    this.progress = this.nav[0].appendChild(progress)
  }

  destroy(){
    super.destroy()
  }

}

const circularClamp = (min,mid,max)=>{
  return mid < min ? max : mid < max ? mid : min
}

Header.selector = `.header__slider`;
module.exports = Header
